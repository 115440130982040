import React, { useState, useEffect } from 'react';
import { fetchTickets, fetchEvents } from '../../../services/api';
import './TicketsTab.css';

function TicketsTab() {


  return (
<p>Soon under Development</p>
  );
}

export default TicketsTab;
